export const EMAIL_IS_SENT_KEY = 'EMAIL_IS_SENT_KEY'
export const IS_STARTED_KEY = 'IS_STARTED'

export function useLocalStorage({ key, fallbackValue }) {
  const [entry, setEntry] = React.useState(fallbackValue)

  React.useEffect(
    () => {
      try {
        const value = window.localStorage.getItem(key)
        if (value) {
          const json = JSON.parse(value)
          setEntry(json)
        }
      } catch { }
    },
    [key]
  )

  function updateEntry(entry) {
    try {
      const json = JSON.stringify(entry)
      window.localStorage.setItem(key, json)
      setEntry(entry)
    } catch { }
  }

  return [entry, updateEntry]
}
